// sagas.js

import { call, put, takeLatest } from "redux-saga/effects";
import { CODEVERIFY } from "../../../utility/constants";
import axios from "axios";
import {
  VERIFY_AUTH_REQUEST,
  verifyAuthSuccess,
  verifyAuthFailure,
} from "../../actions/getAuthCodeActions";

function* getVerifyAuth(action) {
  try {
    const payload = new FormData();
    payload.append("otp", action.payload.otp);

    const response = yield call(axios.post, CODEVERIFY, payload, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.data.status === "Success") {
      yield put(verifyAuthSuccess(response));
    }
  } catch (error) {
    let errorResponse = error.response;
    if (
      errorResponse.status === 400 &&
      errorResponse.data.status === "Failed"
    ) {
      yield put(verifyAuthSuccess(errorResponse));
    } else {
    }
  }
}

export function* getVerifyAuthSaga() {
  yield takeLatest(VERIFY_AUTH_REQUEST, getVerifyAuth);
}
export default getVerifyAuthSaga;
