// Importing action types for auth verification actions
import {
  VERIFY_AUTH_REQUEST,   // Action type for requesting auth verification
  VERIFY_AUTH_SUCCESS,   // Action type for successful auth verification
  VERIFY_AUTH_FAILURE,   // Action type for auth verification failure
} from "../../actions/getAuthCodeActions";  // Importing action types from the respective action file

// Define the initial state for the reducer
const initialState = {
  isAuth: false,         // Initial value for isAuth is false
  isLoading: false,      // Initial value for isLoading is false
  verifyAUTHdata: {},     // Initial value for verifyauthdata is an empty object
  isError: null,         // Initial value for isError is null
};

// Define the getVerifyAuthCodeReducers function
const getVerifyAuthCodeReducers = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case VERIFY_AUTH_REQUEST:
      // When the VERIFY_AUTH_CODE_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,    // Set isLoading to true
        verifyAUTHdata: null,  // Reset verifyauthdata to null
        isError: null,       // Reset isError to null
      };
    case VERIFY_AUTH_SUCCESS:
      // When the VERIFY_AUTH_CODE_SUCCESS action is dispatched
      return {
        ...state,
        isAuth: true,               // Set isAuth to true
        isLoading: false,           // Set isLoading to false
        verifyAUTHdata: action.payload.data,  // Update verifyauthdata with the payload from the action
        isError: null,              // Reset isError to null
      };
    case VERIFY_AUTH_FAILURE:
      // When the VERIFY_AUTH_CODE_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,          // Set isLoading to false
        isError: action.error,     // Update isError with the error from the action
        verifyAUTHdata: null,       // Reset verifyauthdata to null
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the getVerifyAuthCodeReducers as the default export
export default getVerifyAuthCodeReducers;
