// actions.js
// Action types for AUTH verification request, success, and failure
export const VERIFY_AUTH_REQUEST = "VERIFY_AUTH_REQUEST";
export const VERIFY_AUTH_SUCCESS = "VERIFY_AUTH_SUCCESS";
export const VERIFY_AUTH_FAILURE = "VERIFY_AUTH_FAILURE";

// Action creator for AUTH verification request
export const verifyAuthRequest = (payload) => ({
  type: VERIFY_AUTH_REQUEST,
  payload,
});

// Action creator for AUTH verification success
export const verifyAuthSuccess = (verifyAUTHdata) => ({
  type: VERIFY_AUTH_SUCCESS,
  payload: verifyAUTHdata,
});

// Action creator for AUTH verification failure
export const verifyAuthFailure = (error) => ({
  type: VERIFY_AUTH_FAILURE,
  payload: error,
});
