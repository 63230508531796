// sagas.js

import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { LOGINAPI } from "../../../utility/constants";
import {
  GENERATE_OTP_REQUEST,
  generateOTPSuccess,
  generateOTPFailure,
} from "../../actions/generateotpActions";

function* generateOtp(action) {
  try {

    let isLoginbyMobile = action.payload.isLoginbyMobile
    const loginPayloadData = new FormData();
    loginPayloadData.append("country", localStorage.getItem("countryCode"));
    if (!isLoginbyMobile) {
      loginPayloadData.append("email", action.payload.email);
      // localStorage.setItem("email", action.payload.email);
      localStorage.setItem("username", action.payload.email);
      localStorage.setItem("userType", 'email');
      loginPayloadData.append("account_type", "2");
    } else {
      loginPayloadData.append(
        "mobile",
        action.payload.mobile
      );
      localStorage.setItem("username", action.payload.mobile);
      localStorage.setItem("userType", 'mobile');

      loginPayloadData.append("account_type", "1");
    }
    loginPayloadData.append("uuid", localStorage.getItem("uuid"));
    loginPayloadData.append("os", action.payload.os);
    loginPayloadData.append("device_type", action.payload.name);

    loginPayloadData.append("device_name", action.payload.name + " " + "(" + action.payload.os + ")");
    loginPayloadData.append("name", action.payload.name + " " + "(" + action.payload.os + ")");
    loginPayloadData.append("version", localStorage.getItem("version"));
    loginPayloadData.append("tag", "0");

    const response = yield call(axios.post, LOGINAPI, loginPayloadData, {
      headers: {

        'token': localStorage.getItem("token"),
      },
    });

    if (response.status === 201 && response.data.status === "Success") {
      yield put(generateOTPSuccess(response.data));
    }
  } catch (error) {

    if (error.response.status === 400) {
      yield put(generateOTPSuccess(error.response.data));
    }


  }
}

function* generateOtpSaga() {
  yield takeLatest(GENERATE_OTP_REQUEST, generateOtp);
}

export default generateOtpSaga;
